<template>
  <div class="full-height">
    <content-layout
      :breadcrumbs-items="breadcrumbsItems"
      toolbar-enabled
      toolbar-flat
      :search-enabled="false"
      search-bar-in-toolbar
      :search-text.sync="searchText"
      :show-empty-state="showEmptyState"
      :loading="loading"
    >
      <template #toolbar>
        <cz-button
          :title="$t('customer.catalogManagementPage.createButtonTitle')"
          color="button"
          dark
          :icon-src="mdiPlusCircleOutline"
          @click="onCreateCatalogItem"
        />
      </template>
      <cz-data-table
        class="mt-2"
        v-bind="catalogItemsTable"
        :loading="loading"
        height="calc(100vh - 300px)"
        :options.sync="catalogItemsOptions"
      >
        <template #item.itemPrice="{ item }">
          {{ formatAmountWithCurrency(item.itemPrice, item.currency) }}
        </template>
        <template #item.createdAt="{ item }">
          {{ formatDateToDisplay(item.createdAt) }}
        </template>
        <template #item.createdBy="{ item }">
          <user-info-menu :user="item.user" v-if="item.user" />
        </template>
        <template #item.actions="{ item }">
          <cz-menu-button
            :button-icon-src="mdiDotsHorizontalCircleOutline"
            :button-icon-size="22"
            flat
            button-icon-color="button"
            :menu-items="actionMenuItems"
            @action:clicked="onCatalogItemActionClicked($event, item)"
            clickable
            button-large
          />
        </template>
      </cz-data-table>
    </content-layout>
    <manage-catalog-item-dialog
      v-if="catalogItemDialog.show"
      v-model="catalogItemDialog.show"
      v-bind="catalogItemDialog"
      @close="catalogItemDialog.show = false"
      @on-submit="saveCatalog"
    />
  </div>
</template>

<script>
import { CzButton, CzMenuButton, CzDataTable } from '@/components';
import UserInfoMenu from '@/domain/auth/components/UserInfoMenu.vue';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import {
  formatDateTimeToDisplay,
  formatDateToDisplay
} from '@/shared/services/dates.service';
import {
  mdiChevronLeft,
  mdiPlusCircleOutline,
  mdiTrashCanOutline,
  mdiDotsHorizontalCircleOutline,
  mdiPencil
} from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import ManageCatalogItemDialog from '../components/ManageCatalogItemDialog.vue';
import {
  getCatalogItems,
  createCatalogItem,
  updateCatalogItem
} from '@/core/api';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';

const initialCatalogItem = {
  itemCode: '',
  itemName: '',
  currency: 'ILS',
  itemUnitOfMeasure: '',
  priceType: '',
  itemPrice: undefined,
  itemDescription: ''
};

Object.freeze(initialCatalogItem);
export default {
  name: 'CatalogManagementPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    ManageCatalogItemDialog,
    CzDataTable,
    CzMenuButton,
    UserInfoMenu,
    CzButton
  },
  created() {
    this.countItems();
  },
  computed: {
    ...mapFields('auth', ['accountId']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.manageCatalogTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    actionMenuItems() {
      return [
        {
          id: 'edit',
          title: this.$t('customer.catalogManagementPage.editCatalogItem'),
          icon: mdiPencil
        },
        {
          id: 'delete',
          title: this.$t('customer.catalogManagementPage.deleteCatalogItem'),
          icon: mdiTrashCanOutline
        }
      ];
    }
  },
  data() {
    return {
      mdiPlusCircleOutline,
      mdiChevronLeft,
      mdiTrashCanOutline,
      mdiDotsHorizontalCircleOutline,
      mdiPencil,
      loading: false,
      searchText: '',
      showEmptyState: false,
      catalogItemsOptions: {},
      catalogItemsTable: {
        loading: false,
        items: [],
        serverItemsLength: -1,
        columns: [
          {
            text: this.$t('customer.catalogManagementPage.itemCode'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'itemCode',
            sortable: false
          },
          {
            text: this.$t('customer.catalogManagementPage.itemName'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'itemName',
            sortable: false
          },
          {
            text: this.$t('customer.catalogManagementPage.itemPrice'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'itemPrice',
            sortable: true
          },
          {
            text: this.$t('customer.catalogManagementPage.unitOfMeasure'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'itemUnitOfMeasureText',
            sortable: true
          },
          {
            text: this.$t('customer.catalogManagementPage.priceType'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'priceTypeText',
            sortable: true
          },
          {
            text: this.$t('customer.catalogManagementPage.creationDate'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'createdAt',
            sortable: true
          },
          {
            text: this.$t('customer.catalogManagementPage.createdBy'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'createdBy',
            sortable: true
          },
          {
            text: this.$t('common.actions'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'actions',
            sortable: true,
            align: 'center',
            width: 200
          }
        ]
      },
      catalogItemDialog: {
        show: false,
        item: null,
        loading: false
      }
    };
  },
  methods: {
    formatDateTimeToDisplay,
    formatDateToDisplay,
    formatAmountWithCurrency,
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    ...mapActions('customer', ['loadAccountWorkflows']),
    async countItems() {
      try {
        this.loading = true;
        const { total } = await getCatalogItems({
          $limit: 0,
          accountId: this.accountId,
          isArchived: false,
          catalogId: 1
        });
        this.showEmptyState = total === 0;
      } finally {
        this.loading = false;
      }
    },
    async loadData(options) {
      const { itemsPerPage, page, sortBy, sortDesc } = options;
      const $skip = (page - 1) * itemsPerPage;

      const query = {
        $limit: itemsPerPage,
        $skip
      };

      if (sortBy?.length) {
        query.$sort = {
          [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
        };
      }
      try {
        this.catalogItemsTable.loading = true;
        this.catalogItemsTable.items = [];
        const { data, total } = await getCatalogItems({
          ...query,
          accountId: this.accountId,
          isArchived: false,
          catalogId: 1
        });
        this.catalogItemsTable.serverItemsLength = total;
        this.catalogItemsTable.items = data;
      } finally {
        this.catalogItemsTable.loading = false;
      }
    },
    async saveCatalog(form) {
      try {
        this.catalogItemDialog.loading = true;
        if (form._id) {
          const updatedItem = await updateCatalogItem(form._id, form);
          const _catalogItems = cloneDeep(this.catalogItemsTable.items);
          const index = _catalogItems.findIndex(
            (catalogItem) => catalogItem._id === form._id
          );
          if (index !== -1) {
            _catalogItems[index] = updatedItem;
          }
          this.catalogItemsTable.items = _catalogItems;
          this.showSuccessToastMessage(
            this.$t(
              'customer.catalogManagementPage.catalogItemUpdatedSuccessMessage',
              [form.itemCode]
            )
          );
        } else {
          const createdItem = await createCatalogItem({
            ...form,
            accountId: this.accountId,
            catalogId: 1
          });
          if (this.showEmptyState) {
            this.showEmptyState = false;
          } else {
            this.catalogItemsTable.items.push(createdItem);
          }

          this.showSuccessToastMessage(
            this.$t(
              'customer.catalogManagementPage.catalogItemCreatedSuccessMessage',
              [form.itemCode]
            )
          );
        }
        this.catalogItemDialog.show = false;
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.catalogItemDialog.loading = false;
      }
    },
    async deleteCatalogItem(item) {
      try {
        await updateCatalogItem(item._id, {
          accountId: item.accountId,
          isArchived: true
        });

        const index = this.catalogItemsTable.items.findIndex(
          (catalogItem) => catalogItem._id === item._id
        );
        if (index !== -1) {
          this.catalogItemsTable.items.splice(index, 1);
        }
      } finally {
      }
    },
    onCreateCatalogItem() {
      this.catalogItemDialog.item = cloneDeep(initialCatalogItem);
      this.catalogItemDialog.show = true;
    },
    onCatalogItemActionClicked({ id }, item) {
      if (id === 'delete') {
        this.deleteCatalogItem(item);
      } else if (id === 'edit') {
        this.catalogItemDialog.item = cloneDeep(item);
        this.catalogItemDialog.show = true;
      }
    }
  },
  watch: {
    catalogItemsOptions: {
      handler(value) {
        if (value.hasOwnProperty('itemsPerPage')) {
          this.loadData(value);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
